@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-black;
  }
  h1, .h1 {
    @apply font-display text-gray-900 dark:text-gray-100 text-5xl font-extrabold;
    line-height: 1.125;
  }
  h2, .h2 {
    @apply font-display text-gray-900 dark:text-gray-100 text-4xl font-bold;
    line-height: 1.125;
  }
  h3, .h3 {
    @apply font-display text-gray-900 dark:text-gray-100 text-3xl font-medium;
    line-height: 1.25;
  }
  h4, .h4 {
    @apply font-display text-gray-900 dark:text-gray-100 text-2xl font-medium;
    line-height: 1.25;
  }
  h5, .h5 {
    @apply font-display text-gray-900 dark:text-gray-100 text-xl font-medium;
    line-height: 1.334;
  }
  h6, .h6 {
    @apply font-display text-gray-900 dark:text-gray-100 text-lg font-medium;
    line-height: 1.125;
  }
  .eyebrow {
    @apply font-display text-gray-900 dark:text-gray-100 text-base font-extrabold uppercase;
    line-height: 1.43;
  }
  p, .body1 {
    @apply font-body text-gray-900 dark:text-gray-100 text-base;
    line-height: 1.43;
  }
  .body2 {
    @apply font-body text-gray-900 dark:text-gray-100 text-lg;
    line-height: 1.5;
  }
  .body3 {
    @apply font-body text-gray-900 dark:text-gray-100 text-sm;
    line-height: 1.334;
  }
  .body4 {
    @apply font-body text-gray-900 dark:text-gray-100 text-xs;
    line-height: 1.3;
  }
  label, .label1 {
    @apply font-body text-gray-900 dark:text-gray-100 text-base font-medium;
    line-height: 1.43;
  }
  .label2 {
    @apply font-body text-gray-900 dark:text-gray-100 text-sm font-medium;
    line-height: 1.25;
  }
  ol {
    @apply list-decimal ml-4;
  }
  ul {
    @apply list-disc ml-4;
  }
  li {
    @apply font-body text-gray-900 dark:text-gray-100 text-base;
    line-height: 1.43;
  }
  input, button {
    @apply focus:outline-none;
  }
  a {
    @apply text-blue-700 dark:text-blue-500;
  }
  a:hover {
    @apply text-blue-600 dark:text-blue-300 no-underline;
  }
  a:active {
    @apply text-blue-800 dark:text-blue-400;
  }

  /* utility */
  .flex-centered {
    @apply flex items-center justify-center;
  }
}

@layer components {
  /* General */
  .page-container {
    @apply overflow-x-hidden max-w-limit mx-auto;
  }

  /* Nav Bar */
  .nav-container {
    @apply fixed inset-x-0 z-10 transition-all duration-200 ease-in-out;
  }
  .nav-container.nav-container-show {
    @apply top-0;
  }
  .nav-container.nav-container-hide {
    @apply -top-14 xl:-top-16;
  }
  .nav-bar {
    @apply absolute inset-x-0 z-10;
  }
  .nav-bar.nav-has-search-value {
    @apply bg-gray-800 h-screen z-50 lg:h-auto lg:bg-transparent;
  }
  .nav-bar-items {
    @apply flex justify-between items-center bg-gray-900/40 h-14 xl:h-16;
  }
  .nav-container-scrolled .nav-bar-items {
    @apply bg-gray-900/80;
  }
  .nav-bar.nav-has-search-value .nav-bar-items {
    @apply bg-gray-900 lg:bg-gray-900/40;
  }
  .nav-container-scrolled .nav-bar.nav-has-search-value .nav-bar-items {
    @apply bg-gray-900 lg:bg-gray-900/80;
  }
  .nav-button,
  .nav-button-inverted,
  .nav-button-open {
    @apply rounded-full flex-centered h-8 w-8 xl:h-10 xl:w-10;
  }
  .nav-button {
    @apply bg-transparent text-white;
  }
  .nav-button-inverted {
    @apply bg-white text-blue-700;
  }
  .nav-button-open {
    @apply bg-white text-blue-600 border-2 border-blue-600;
  }
  .nav-bar.search-open .nav-logo {
    @apply animate-slide-out-left lg:animate-none;
  }
  .nav-bar.search-closed .nav-logo {
    @apply animate-slide-in-left lg:animate-none;
  }
  .nav-bar.search-open .nav-account-button {
    @apply animate-slide-out-right lg:animate-none;
  }
  .nav-bar.search-closed .nav-account-button {
    @apply animate-slide-in-right lg:animate-none;
  }
  .nav-back-button {
    @apply absolute top-3 left-3 lg:hidden;
    transform: translateX(-3rem);
  }
  .nav-bar.search-open .nav-back-button {
    @apply animate-slide-in-left;
  }
  .nav-bar.search-closed .nav-back-button {
    @apply animate-slide-out-left;
  }
  .nav-search {
    @apply flex items-center absolute top-2 right-16 h-10 w-10 xl:top-3 lg:right-20 lg:transition-width lg:duration-500 z-10;
  }
  .nav-bar.search-open .nav-search {
    @apply animate-expand-search-mobile border rounded-lg border-white lg:right-20 lg:w-96 xl:w-140 lg:animate-none;
  }
  .nav-bar.search-open.nav-has-search-value .nav-search {
    @apply lg:bg-gray-800 lg:rounded-t-lg lg:rounded-b-none;
  }
  .nav-bar.search-closed .nav-search {
    @apply animate-collapse-search-mobile lg:animate-none;
  }
  .nav-search-input-label span {
    @apply hidden;
  }
  .nav-search-input-label,
  .nav-search-input-container {
    @apply w-0;
  }
  .nav-bar.search-open .nav-search-input-label,
  .nav-bar.search-open .nav-search-input-container {
    @apply w-full;
  }
  .nav-search-input {
    @apply bg-transparent w-0 text-white placeholder:text-white;
  }
  .nav-bar.search-open .nav-search-input {
    @apply w-full h-10;
  }
  .nav-search-button {
    @apply h-10 w-10;
  }
  .nav-clear-button {
    @apply hidden;
  }
  .nav-bar.search-open.nav-has-search-value .nav-clear-button {
    @apply absolute right-0 flex z-20;
  }
  .nav-search-results-container {
      @apply absolute top-12 list-none m-0 p-0 lg:top-auto -left-[49px] -right-[17px] lg:-left-px lg:-right-px lg:overflow-y-auto lg:max-h-80vh;
  }
  .nav-search-results-container-open {
    @apply bg-gray-800 lg:border-l lg:border-b lg:border-r lg:border-white lg:rounded-t-none lg:rounded-b-lg;
  }
  .nav-search-result {
    @apply block py-2 px-4 lg:px-3 text-white cursor-pointer text-sm truncate;
  }
  .nav-search-result .type {
    @apply text-gray-400;
  }
  .nav-search-result .bold {
    @apply font-bold;
  }
  .nav-search-result-highlighted {
    @apply bg-gray-700;
  }

  /* Footer */
  footer {
    @apply bg-gray-800 flex-grow-0;
  }
  footer p {
    @apply body3 font-medium;
  }


  /* Carousel */
  .carousel .slick-list,
  .carousel .slick-slider,
  .carousel .slick-track,
  .hero .slick-list,
  .hero .slick-slider,
  .hero .slick-track {
    @apply relative block;
  }
  .carousel .slick-loading .slick-slide,
  .carousel .slick-loading .slick-track,
  .hero .slick-loading .slick-slide,
  .hero .slick-loading .slick-track {
    @apply invisible;
  }
  .carousel .slick-slider,
  .hero .slick-slider {
    @apply box-border select-none touch-pan-y;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }
  .carousel .slick-list,
  .hero .slick-list {
    @apply m-0 p-0 lg:overflow-hidden;
  }
  .carousel .slick-list:focus,
  .hero .slick-list:focus {
    @apply outline-0;
  }
  .carousel .slick-list.dragging,
  .hero .slick-list.dragging {
    @apply cursor-grab;
  }
  .carousel .slick-slider .slick-list,
  .carousel .slick-slider .slick-track,
  .hero .slick-slider .slick-list,
  .hero .slick-slider .slick-track {
    @apply translate-x-0 translate-y-0;
  }
  .carousel .slick-track,
  .hero .slick-track {
    @apply top-0 left-0;
  }
  .carousel .slick-track:after,
  .carousel .slick-track:before,
  .hero .slick-track:after,
  .hero .slick-track:before {
    @apply table;
    content: "";
  }
  .carousel .slick-track:after,
  .hero .slick-track:after {
    @apply clear-both;
  }
  .carousel .slick-slide,
  .hero .slick-slide {
    @apply hidden float-left h-full min-h-px border-0;
  }
  .carousel .slick-slide img,
  .carousel .slick-initialized .slick-slide,
  .hero .slick-slide img,
  .hero .slick-initialized .slick-slide {
    @apply block;
  }
  .carousel .slick-slide.slick-loading img,
  .carousel .slick-arrow.slick-hidden,
  .hero .slick-slide.slick-loading img,
  .hero .slick-arrow.slick-hidden {
    @apply hidden;
  }
  .carousel .slick-slide.dragging img,
  .hero .slick-slide.dragging img {
    @apply pointer-events-none;
  }
  .carousel .slick-next,
  .carousel .slick-prev {
    @apply hidden absolute p-0 text-white w-6 h-6 border-0 outline-0 leading-0 cursor-pointer lg:block;
  }
  .carousel.carousel-slides-2 .slick-next,
  .carousel.carousel-slides-2 .slick-prev {
    @apply top-3/10 xl:top-1/3;
  }
  .carousel.carousel-slides-3 .slick-next,
  .carousel.carousel-slides-3 .slick-prev {
    @apply top-1/4 xl:top-3/10;
  }
  .carousel.carousel-slides-4 .slick-next,
  .carousel.carousel-slides-4 .slick-prev {
    @apply top-15/100 xl:top-1/5;
  }
  .carousel .slick-next:focus,
  .carousel .slick-next:hover,
  .carousel .slick-prev:focus,
  .carousel .slick-prev:hover {
    @apply text-gray-300 outline-0;
  }
  .carousel .slick-next.slick-disabled,
  .carousel .slick-prev.slick-disabled {
    @apply opacity-0 hidden;
  }
  .carousel .slick-prev {
    @apply -left-6;
  }
  .carousel .slick-next {
    @apply -right-6;
  }
  .carousel {
    @apply -mx-2 lg:-mx-3;
  }
  .carousel .course-card {
    @apply px-2 lg:px-3;
  }
  .carousel-slides-2 .course-card .thumbnail {
    @apply rounded-lg;
  }
  .carousel-slides-3 .course-card .thumbnail {
    @apply rounded md:rounded-lg;
  }
  .carousel-slides-4 .course-card .thumbnail {
    @apply rounded;
  }


  /* Hero slider navigation */
  .hero .slick-dots {
    @apply absolute block w-full p-0 m-0 list-none text-center bottom-2;
  }
  .hero .slick-dots li {
    @apply inline-block relative w-6 h-1 my-0 mx-1 p-0 cursor-pointer;
  }
  .hero .slick-dots li button {
    @apply block w-6 h-1 p-0 cursor-pointer text-transparent border-0 outline-none bg-white opacity-60;
  }
  .hero .slick-dots li.slick-active button {
    @apply opacity-100;
  }
  .hero .slick-dots li button:hover,
  .hero .slick-dots li button:focus {
    @apply outline-none;
  }
  .hero-slide {
    @apply relative;
  }

  /* Hero top margin with Onboarding Banner */
  .homepage-wrapper {
    @apply min-h-screen
  }

  .nav-container-with-account-message + .homepage-wrapper {
    @apply pt-24;
  }

  /* Course card */
  .course-card {
    @apply mb-10;
  }
  .course-card .thumbnail {
    @apply overflow-hidden mb-4 relative rounded md:rounded-lg;
    padding-top: 56.25%;
  }
  .course-card .thumbnail img {
    @apply w-full absolute top-0 left-0;
  }
  .course-card .link {
    @apply border-0;
  }
  .course-card .name {
    @apply h6 md:h5 md:mb-2;
  }
  .course-card .description {
    @apply hidden body1 md:block;
  }
  .course-card .tags,
  .lesson-info .tags {
    @apply hidden list-none m-0 p-0 md:flex md:flex-wrap;
  }
  .course-card .tag,
  .course-header .tag,
  .unit-header .tag,
  .lesson-info .tag {
    @apply pr-2 pt-2;
  }
  .course-card .tag-link,
  .course-header .tag-link,
  .unit-header .tag-link,
  .lesson-info .tag-link {
    @apply flex-centered h-5 body4 px-2 rounded-lg text-white hover:no-underline;
  }

  /* Course Page */
  .detail-page-layout {
    @apply px-6 lg:px-12 lg:grid lg:grid-cols-12;
  }
  .detail-page-content,
  .course-header-thumbnail,
  .lesson-video {
    @apply lg:col-start-2 lg:col-span-10;
  }
  .lesson-video {
    @apply flex flex-row;
  }
  .live-chat {
    @apply h-full w-60 ;
  }
  .video-live-chat {
    @apply w-full h-full;
  }
  .course-header-thumbnail,
  .lesson-video {
    @apply -mx-6 lg:mx-0 lg:pt-10;
  }
  .course-header,
  .lesson-details {
    @apply bg-gray-800;
  }
  .course-header .detail-page-content {
    @apply pt-6 pb-4 md:py-10;
  }
  .lesson-details .detail-page-content {
    @apply pt-6 pb-8 md:py-10;
  }
  .course-units .detail-page-layout {
    @apply py-10;
  }
  .course-header .name {
    @apply h4 md:h3 lg:h2;
  }
  .course-header .description {
    @apply body1 md:body2 pt-4;
  }
  .course-header .content p {
    @apply body1 md:body2 pt-4;
  }
  .course-header .content .o-button {
    @apply ui-link
  }
  .course-header .tags,
  .unit-header .tags {
    @apply list-none m-0 p-0 flex flex-wrap;
  }

  .unit-header {
    @apply mb-10;
  }
  .unit-header .name {
    @apply mb-2 md:h3;
  }
  .unit-header .description {
    @apply body1;
  }

  .lesson-icon-container {
    @apply w-14 h-14 rounded flex-centered mr-4 md:mr-8 basis-14 shrink-0;
  }
  .lesson-icon-container.lesson-icon-completed {
    @apply bg-gray-800;
  }
  .lesson-icon-container.lesson-icon-pristine {
    background: linear-gradient(0deg, #4723E7 9.6%, #2285F1 90.5%);
  }
  .lesson-icon {
    @apply w-10 h-10;
  }
  .lesson-icon-container.lesson-icon-pristine .lesson-icon {
    @apply text-white;
  }
  .lesson-link {
    @apply flex items-center mb-10;
  }
  .lesson-info h6.pristine {
    @apply md:h4;
  }
  .lesson-info h6.completed {
    @apply text-gray-600 md:h4;
  }


  /* Lesson */
  .video-container {
    @apply relative;
    padding-top: 56.25%;
  }
  .video-player {
    @apply absolute top-0 left-0;
  }
  .lesson-details .name {
    @apply h4 md:h3 xl:h2;
  }
  .download-item {
    @apply py-1;
  }

  /* Account page */
  .account-page-layout {
    @apply px-6 lg:px-20 w-full pt-14;
  }
  .account-page-layout img.subscription-logo {
    @apply p-6 bg-gray-800 w-full md:w-72 mb-6 mt-8 rounded-lg;
  }
  .account-page-layout .account-tier {
    @apply flex flex-row justify-start gap-6 items-center;
  }
  .account-page-layout .subscription .status {
    @apply text-sm text-center text-white px-2 py-1 inline rounded ml-1;
    line-height:2;
  }
  .account-page-layout .subscription .status.active {
    @apply bg-dark-success
  }
  .account-page-layout .subscription .status.canceled {
    @apply bg-dark-error
  }
  .account-page-layout .subscription p {
    @apply text-base my-2;
  }
  .account-page-layout .subscription,
  .account-page-layout .payment-details {
    @apply flex flex-row items-end flex-wrap lg:w-1/2 md:w-full text-white mb-12 gap-6;
  }

  .modal-option-title {
    @apply mb-7 text-[20px] text-white;
    line-height: 1.75;
  }

  /* Search Results page */
  .search-results-page-layout {
    @apply px-6 lg:px-15/100 w-full pt-14 flex flex-row-reverse place-content-start place-items-start gap-32;
  }
  .search-controls {
    @apply px-6 flex flex-row;
  }

  .search-results {
    @apply flex flex-col mt-4 w-140 px-8;
  }

  .search-results .course-card .thumbnail {
    @apply rounded-lg float-left w-56 mr-6 h-40 pt-0;
  }

  .search-results-filters {
    @apply px-6 flex flex-col place-items-start w-56 gap-8;
  }
  /* Breadcrumbs */
  .breadcrumbs {
    @apply flex flex-nowrap items-center mx-0 mt-0 mb-4 p-0 list-none px-4 lg:px-6;
  }
  .breadcrumb {
    @apply hidden min-w-0 md:flex flex-nowrap items-center pr-2 body1 text-white xl:body2;
  }
  .breadcrumb a,
  .breadcrumb .breadcrumb-current {
      @apply truncate;
  }
  .breadcrumb:first-child{
    @apply shrink-0;
  }
  .breadcrumb:nth-last-child(2) {
      @apply shrink-0 md:shrink;
  }
  .breadcrumb:nth-last-child(-n+2) {
    @apply flex;
  }
  .breadcrumb a {
    @apply text-white no-underline;
  }
  .breadcrumb a:hover {
    @apply text-blue-300;
  }
  .breadcrumb a:active {
    @apply text-blue-400;
  }
  .breadcrumb-label {
    @apply hidden md:inline;
  }
  .breadcrumb-back {
    @apply h-5 w-5 md:hidden;
  }
  .breadcrumb-separator {
    @apply shrink-0 ml-2 h-5 w-5 hidden md:inline;
  }


  /* UI Components */
  .ui-load-bar {
    @apply relative w-full h-1 overflow-x-hidden;
  }
  .ui-load-bar-line {
    @apply absolute bg-gray-700 h-1 w-[150%];
  }
  .ui-load-bar-sub-line {
    @apply absolute bg-white/80 h-1;
  }
  .ui-load-bar-inc {
   @apply animate-load-bar-increase;
  }
  .ui-load-bar-dec {
    @apply animate-load-bar-decrease;
  }

  button {
    @apply font-display;
  }
  .ui-button {
    @apply font-display w-full flex items-center justify-center rounded-lg font-medium truncate;
  }
  .ui-button.ui-large {
    @apply h-10 text-base px-5 focus:border-[3px];
  }
  .ui-button.ui-small {
    @apply h-8 text-sm px-5 focus:border-2;
  }
  .ui-button.ui-primary {
    @apply text-white bg-blue-700 hover:bg-blue-600 focus:border-blue-500 focus:border-2 active:bg-blue-800 active:border-0 dark:bg-blue-600 dark:hover:bg-blue-500 dark:active:bg-blue-700;
  }
  .ui-button.ui-primary.ui-disabled {
    @apply text-gray-500 bg-gray-200 hover:bg-gray-200 focus:border-0 active:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-800 dark:active:bg-gray-800;
  }
  .ui-button.ui-secondary {
    @apply text-blue-700 bg-white border-blue-700 border hover:bg-gray-100 hover:border-blue-600 hover:text-blue-600 focus:text-blue-700 focus:border-blue-700 active:text-blue-800 active:border-blue-800 active:border;
  }
  .ui-button.ui-primary.ui-success {
    @apply text-black bg-blue-500 border-blue-500 border hover:bg-blue-400 hover:border-blue-400 hover:text-black focus:text-black focus:border-blue-500 active:text-blue-600 active:border-blue-600 active:border;
  }
  .ui-button.ui-primary.ui-success.ui-disabled {
    @apply text-gray-600 bg-gray-800 border-gray-800 border hover:bg-gray-800 hover:border-gray-800 hover:text-gray-600 focus:text-gray-400 focus:border-gray-800 active:text-gray-800 active:border-gray-800 active:border;
  }
  .ui-button.ui-primary.ui-warning {
    @apply text-black bg-red-400 border-red-400 border hover:bg-red-300 hover:border-red-300 hover:text-black focus:text-black focus:border-red-400 active:text-red-500 active:border-red-500 active:border;
  }
  .ui-button.ui-secondary.ui-disabled {
    @apply text-gray-500 bg-gray-200 border-gray-400 border dark:bg-gray-800 dark:border-gray-600;
  }
  .ui-button.ui-link {
    @apply text-blue-700 hover:text-blue-600 focus:text-blue-700 focus:border-0 active:border-0 dark:text-blue-500 dark:hover:text-blue-400 dark:focus:text-blue-500 active:text-blue-600;
  }
  .ui-button.ui-icon-link {
    @apply text-blue-700 hover:text-blue-600 focus:text-blue-700 focus:border-0 active:border-0 dark:text-white dark:hover:text-blue-300 dark:focus:text-blue-400 active:text-blue-400;
  }
  .ui-button.ui-icon-link.ui-success {
    @apply text-blue-500 hover:text-blue-400 focus:text-blue-500 focus:border-0 active:border-0 dark:text-blue-400 dark:hover:text-blue-500 dark:focus:text-blue-600 active:text-blue-600;
  }
  .ui-button.ui-link.ui-disabled {
    @apply text-gray-500;
  }
  .ui-button.ui-icon-link.ui-disabled {
    @apply text-gray-300;
  }
  .ui-button.ui-outlined {
    @apply text-blue-500 border-blue-500 border focus:border-2 active:border-0;
  }
  .ui-button.ui-outlined.ui-white {
    @apply text-white border-white border focus:border-2 active:border-0;
  }
  .ui-button.ui-outlined.ui-white.ui-disabled {
    @apply text-gray-600 bg-gray-800 border-gray-600 border hover:bg-gray-800 hover:border-gray-600 hover:text-gray-600 focus:text-gray-400 focus:border-gray-600 active:text-gray-800 active:border-gray-600 active:border;
  }
  .ui-button.ui-outlined.ui-success {
    @apply text-blue-500 border-blue-500 border focus:border-2 active:border-0;
  }
  .ui-button.ui-outlined.ui-warning {
    @apply text-red-400 border-red-400 border focus:border-2 active:border-0;
  }
  .ui-button.ui-link.ui-small,
  .ui-button.ui-link.ui-large,
  .ui-button.ui-icon-link.ui-small,
  .ui-button.ui-icon-link.ui-large {
      @apply h-auto px-0;
  }
  .ui-button .leading-icon {
    @apply mr-2;
  }
  .ui-button .trailing-icon {
    @apply ml-2;
  }

  .ui-button-group {
    @apply rounded-md shadow-sm text-center mb-4
  }

  .ui-button-group > button {
    @apply text-sm font-medium py-2 px-4 border border-gray-900 hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-white focus:text-black dark:border-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-white dark:focus:text-black dark:active:text-black
  }

  .ui-button-group > button:first-child {
    @apply rounded-l-md
  }

  .ui-button-group > button:last-child {
    @apply rounded-r-md
  }

  .ui-checkbox {
    @apply mr-2 border-gray-500 hover:border-gray-600 focus:border-blue-800;
  }
  .ui-checkbox.ui-error {
    @apply border-red-700 hover:border-red-800 focus:border-red-800;
  }

  .ui-text-input {
    @apply text-base w-full h-10 px-3 border rounded-lg bg-white text-gray-900 autofill:shadow-fill-white autofill:text-fill-gray-900 dark:bg-gray-700 dark:text-white dark:autofill:shadow-fill-gray-700 dark:autofill:text-fill-white border-gray-500 hover:border-white focus:border-blue-800 dark:border-gray-800 dark:hover:border-gray-700 dark:focus:border-white dark:focus:bg-gray-700;
  }
  .ui-text-input.ui-error {
    @apply text-gray-900 border-red-700 hover:border-red-800 focus:border-red-800 dark:text-white dark:border-red-500 dark:focus:border-red-500 dark:hover:border-red-500;
  }
  .ui-input-label .ui-text-input.ui-error {
    @apply text-gray-900 dark:text-white;
  }
  .ui-text-input.ui-disabled {
    @apply border-gray-500 text-gray-700 bg-gray-100 dark:bg-gray-800 dark:text-gray-700 dark:border-gray-800;
  }

  .ui-input-label {
    @apply label1;
  }
  .ui-input-label .ui-error {
    @apply text-red-700 dark:text-red-500;
  }

  .ui-description {
    @apply body3 pt-2 text-gray-700;
  }

  .ui-error-message {
    @apply body3 pt-2 text-red-700 dark:text-red-500;
  }

  .ui-select-input {
    @apply dark:bg-gray-800 dark:hover:bg-gray-700 relative w-full rounded-lg pl-5 pr-10 h-10 text-left cursor-pointer focus:outline-none dark:text-white body1;
  }
  .ui-select-input.ui-select-input-open {
    @apply rounded-t-lg rounded-b-none;
  }
  .ui-select-input-label {
    @apply block truncate;
  }
  .ui-select-input-arrow {
    @apply absolute inset-y-0 right-5 flex items-center pointer-events-none;
  }
  .ui-select-input-arrow-icon {
    @apply h-5 w-5 dark:text-white;
  }
  .ui-select-options {
    @apply list-none m-0 absolute z-10 w-full dark:bg-gray-800 max-h-60 rounded-b-lg overflow-auto border-t dark:border-gray-900;
  }
  .ui-select-option {
    @apply h-10 cursor-pointer select-none relative flex items-center py-2 pl-5 pr-10 dark:text-white;
  }
  .ui-select-option-active {
    @apply dark:bg-gray-700;
  }
  .ui-select-option-label {
    @apply block truncate;
  }
  .ui-select-option-label-selected {
    @apply font-medium;
  }
  .ui-select-option-check {
    @apply absolute inset-y-0 right-5 flex items-center;
  }
  .ui-select-option-check-icon {
    @apply h-4 w-4;
  }

  .ui-alert {
    @apply max-w-full md:max-w-screen-lg sm:max-w-screen-md xs:max-w-screen-sm mb-4;
  }
  .ui-alert .success {
    @apply bg-dark-success;
  }
  .ui-alert .warning {
    @apply bg-dark-error;
  }

  .ui-inline-button {
    @apply underline;
  }

  .checkout-card-wrapper {
    @apply flex sm:flex-row md:flex-col overflow-hidden shadow-dark rounded-md border-2 border-transparent justify-center;
  }

  .checkout-card-body {
    @apply  flex flex-col items-center justify-center px-4 pt-2 pb-6 space-y-2;
  }

  .checkout-card-body .checkout-card-title {
    @apply mt-2 text-lg font-medium leading-3 capitalize;
  }

  .checkout-card-body .checkout-card-discount {
    @apply rounded-md px-[8px] py-[2px] border border-gray-700 bg-green-100 text-sm text-gray-800;
  }

  .checkout-card-body .checkout-card-plan-logo {
    @apply lg:w-16 md:w-12;
  }

  .checkout-card-body .checkout-card-billing-cycle {
    @apply text-sm dark:text-gray-400 pt-2 pl-1;
  }

  .checkout-card-body .checkout-card-original-price {
    @apply text-sm dark:text-gray-400 leading-3 line-through;
  }

  .checkout-card-body .checkout-annual-notice,
  .checkout-card-feature-list .checkout-annual-notice {
    @apply text-sm dark:text-gray-400 leading-3;
  }

  .checkout-card-feature-list {
    @apply flex flex-col text-sm justify-center lg:items-start pt-1 pb-8 lg:px-2 lg:ml-8 md:ml-4;
  }

  .checkout-card-feature-list .checkout-card-feature-list-header {
    @apply text-white italic md:pl-4 text-sm font-normal;
  }

  .checkout-card-feature-list .checkout-card-feature-list-wrapper {
    @apply self-stretch space-y-2 my-2 mx-6;
  }

  .checkout-card-feature-list .checkout-card-feature-list-wrapper .checkout-card-feature-list-item {
    @apply justify-center space-x-2 text-sm leading-3 italic;
  }

  .checkout-billing-information {
    @apply grid max-w-md grid-cols-1 gap-6 xs:gap-5 mx-auto auto-rows-fr xs:auto-rows-min md:grid-cols-2 sm:max-w-full mb-4 mt-8;
  }

  .checkout-billing-information input.ui-disabled {
    @apply border dark:border-gray-700;
  }

  .checkout-submit-button-wrapper {
    @apply flex flex-col justify-center items-center;
  }

  .card-tier-selected {
    @apply border-2 dark:border-blue-500;
  }

  .product-price {
    @apply flex flex-row font-medium text-2xl;
  }

  .checkout-card-wrapper.card-tier-launch,
  .checkout-card-wrapper.card-tier-scale {
    @apply mt-3;
  }

  .card-tier-launch .product-price {
    @apply dark:text-aqua-300;
  }

  .card-tier-scale .product-price {
    @apply dark:text-purple-300;
  }

  .card-tier-grow .product-price {
    @apply dark:text-blue-500 text-3xl;
  }

  .card-tier-grow .checkout-card-billing-cycle {
    @apply mt-2;
  }
}
